import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

// Core
import Theme from '@refera/ui-core'

// Icons
import {
  SendSquare as IconSendSquare,
  DocumentDownload as IconDocumentDownload,
} from '@refera/ui-icons'
import Svg from '_/components/svg'
import Empty from '_assets/icons/ic-no-files.svg'

// Components
import styled, { css } from 'styled-components'
import { Typography, Button, Loader } from '@refera/ui-web'
import AttachmentFile from './AttachmentFile'
import ZipFilesAndDownload from './DownloadFiles'
import { useToast } from '_/hooks/use-toast'

const listAcceptedFormats = formats => formats[Object.keys(formats)[0]].join(', ').toUpperCase()

const Attachments = styled(
  forwardRef(
    (
      {
        accept,
        label,
        files,
        readOnly,
        content,
        multiple,
        downloadable,
        canImportFiles,
        onDrop,
        onItemClick,
        onRemoveItem,
        onImportFiles,
        required = false,
        boldLabel = true,
        ...rest
      },
      ref
    ) => {
      const [loading, setIsLoading] = useState(false)

      const IMG_SIZE_LIMIT = 5000000 // 5 MB in bytes
      const VIDEO_SIZE_LIMIT = 100000000 // 100 MB in bytes

      const fileSizeValidator = file => {
        const isImage = file.type.includes('image')
        const isVideo = file.type.includes('video')

        if (isImage && file.size > IMG_SIZE_LIMIT) {
          return {
            code: 'image-too-large',
            message: `A imagem é maior que 5 MB.`,
          }
        }

        if (isVideo && file.size > VIDEO_SIZE_LIMIT) {
          return {
            code: 'video-too-large',
            message: `O vídeo é maior que 100 MB.`,
          }
        }

        return null
      }

      const { getRootProps, getInputProps, fileRejections } = useDropzone({
        multiple: multiple !== undefined ? multiple : true,
        accept,
        onDrop,
        validator: fileSizeValidator,
      })

      const { showToast } = useToast()

      useEffect(() => {
        const fileSizeError =
          fileRejections[0]?.errors[0]?.code === 'image-too-large' ||
          fileRejections[0]?.errors[0]?.code === 'video-too-large'

        const fileInvalidType = fileRejections[0]?.errors[0]?.code === 'file-invalid-type'

        if (fileSizeError) {
          showToast({
            type: 'error',
            message: fileRejections[0]?.errors[0]?.message,
          })
        }

        if (fileInvalidType) {
          showToast({
            type: 'error',
            message:
              'Não foi possível realizar o upload dos arquivos, pois a extensão de pelo menos um deles não é aceita pelo campo de anexos em questão.',
          })
        }
      }, [fileRejections])

      const Thumbnails = useMemo(
        () =>
          files?.map((file, index) => (
            <AttachmentFile
              readOnly={readOnly}
              content={content}
              onRemove={() => onRemoveItem(index)}
              onClick={() => onItemClick(index)}
              file={file}
              key={`refera-attachment-${crypto.randomUUID()}`}
              index={index}
              label={label}
            />
          )),
        [files, readOnly, content]
      )

      const renderEmptyState = useCallback(
        () => (
          <div className="ReferaAttachment__empty">
            <Svg icon={Empty} style={{ width: '96px', height: '96px' }} />
            <div className="ReferaAttachment__emptyText no-selection">
              <Typography fontSize={14}>Sem Anexo</Typography>
              <Typography fontSize={12} variant="body2" color={Theme.Colors.Grayscale.SixtyFour}>
                Nenhum arquivo foi anexado.
              </Typography>
            </div>
          </div>
        ),
        [files, readOnly]
      )

      const renderComponentItself = useCallback(
        () => (
          <div className="ReferaAttachment__container">
            {!readOnly && (
              <div className="ReferaAttachment__drop-area" {...getRootProps()}>
                <IconSendSquare />
                <div className="ReferaAttachment__text">
                  <span className="ReferaAttachment__title">
                    Arraste aqui ou selecione o arquivo
                  </span>
                  <span className="ReferaAttachment__description">
                    Formatos &nbsp;
                    {listAcceptedFormats(accept)}
                  </span>
                  <button className="no-selection" type="button">
                    Selecionar arquivo
                  </button>
                  <input {...getInputProps()} />
                </div>
              </div>
            )}
            <div className="ReferaAttachment__files">{Thumbnails}</div>
          </div>
        ),
        [files, readOnly]
      )

      const handleDownloadButton = async () => {
        setIsLoading(true)
        await ZipFilesAndDownload(files.filter(_ => _ instanceof File !== true))
        setIsLoading(false)
      }

      const DownloadButton = useMemo(
        () =>
          downloadable && (
            <Button
              disabled={!files.length || files.every(x => x instanceof File)}
              startIcon={<IconDocumentDownload color={Theme.Colors.Primary.Base} />}
              variant="ghost"
              onClick={handleDownloadButton}
              className="ReferaAttachment__download-button"
            >
              <span className="ReferaAttachment__download-button-label">Baixar todos arquivos</span>
            </Button>
          ),
        [files, downloadable]
      )

      const ImportButton = useMemo(
        () =>
          canImportFiles && (
            <Button
              disabled={readOnly}
              startIcon={<IconDocumentDownload color={Theme.Colors.Primary.Base} />}
              variant="ghost"
              onClick={() => onImportFiles()}
              className="ReferaAttachment__download-button"
            >
              <span className="ReferaAttachment__download-button-label">
                Importar anexos do chamado
              </span>
            </Button>
          ),
        [files, downloadable, canImportFiles]
      )

      return (
        <div className="ReferaAttachment__root" ref={ref} {...rest}>
          <div className="ReferaAttachment__toolbar">
            <span
              className={`ReferaAttachment__label no-selection ${required && 'required'} ${
                boldLabel && 'bold'
              }`}
            >
              {label}
            </span>
            <div className="ReferaAttachment__buttons-container">
              <div className="ReferaAttachment__buttons">{ImportButton}</div>
              <div className="ReferaAttachment__buttons">{DownloadButton}</div>
            </div>
          </div>
          <Loader hasBackdrop open={loading} />
          {!files.length && readOnly ? renderEmptyState() : renderComponentItself()}
        </div>
      )
    }
  )
).attrs(({ content }) => ({
  content: content || 'images',
}))`
  && {
    ${({ content }) => css`
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 500px;

      @media (max-width: 640px) {
        min-width: 350px;
      }

      .ReferaAttachment__root {
        width: 100%;
      }

      .ReferaAttachment__empty {
        display: flex;
        flex-direction: row;

        .ReferaAttachment__emptyText {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          justify-content: center;

          p {
            :first-child {
              font-size: ${Theme.Typography.FontSize.XSmall}px !important;
              font-weight: ${Theme.Typography.FontWeight.Medium} !important;
            }

            :last-child {
              font-size: ${Theme.Typography.FontSize.XXSmall}px !important;
              font-weight: ${Theme.Typography.FontWeight.Small} !important;
            }
          }
        }
      }

      .ReferaAttachment__container {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: auto;
        flex: 1;
        width: 100%;

        ${content === 'files' &&
        css`
          .ReferaAttachment__files {
            width: 100%;
            box-sizing: border-box;
            flex-direction: row;
            margin-top: 0;
            overflow: auto;
            flex-wrap: wrap !important;
            padding-top: 8px;

            div:not(:last-child) {
              margin-right: 8px;
            }

            div {
              overflow-y: hidden !important;
              padding-right: 8px;
            }
          }

          .ReferaAttachment__drop-area {
            width: 100%;
            height: fit-content;
            box-sizing: border-box;
          }
        `};
      }

      .ReferaAttachment__download-button {
        padding: 0 0 4px !important;

        :hover {
          background-color: transparent !important;
        }

        .MuiButton-startIcon {
          @media (max-width: 640px) {
            margin-right: 0 !important;
          }
        }
      }

      .ReferaAttachment__download-button-label {
        font-size: 12px;
        @media (max-width: 640px) {
          display: none;
        }
      }

      .ReferaAttachment__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0;
        width: 100%;
      }

      .ReferaAttachment__buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0;
        width: 400px;
      }

      .ReferaAttachment__label {
        font-family: ${Theme.Typography.FontFamily.Roboto};
        font-size: 12px;
        margin-bottom: 6px;
        align-self: end;
        display: flow;
        max-width: 65%;

        @media (max-width: 640px) {
          max-width: 85%;
        }
      }

      .bold {
        color: ${Theme.Colors.Grayscale.OneThousand};
        font-family: ${Theme.Typography.FontFamily.DMSans};
        font-weight: ${Theme.Typography.FontWeight.Medium};
      }

      .required {
        ::after {
          color: ${Theme.Colors.Red.Base};
          margin-left: 4px;
          content: '*';
        }
      }

      .ReferaAttachment__drop-area {
        background-color: ${Theme.Colors.Grayscale.Four};
        border: 1px dashed ${Theme.Colors.Grayscale.Twelve};
        padding: 18px;
        display: flex;
        font-size: 36px;

        :active {
          border-color: ${Theme.Colors.Primary.Base};

          svg,
          path {
            fill: ${Theme.Colors.Primary.Base};
          }
        }

        svg,
        path {
          fill: ${Theme.Colors.Grayscale.SixtyFour};
        }

        button {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: inherit;
          display: inline-block;
          width: fit-content;
          text-decoration: underline;
          font-size: ${Theme.Typography.FontSize.XXSmall}px;
          color: ${Theme.Colors.Primary.Base};
        }

        .ReferaAttachment__text {
          display: flex;
          flex-direction: column;
          margin-left: 18px;

          .ReferaAttachment__title {
            color: ${Theme.Colors.Grayscale.Eighty};
            font-family: ${Theme.Typography.FontFamily.Roboto};
            font-weight: ${Theme.Typography.FontWeight.Small};
            font-size: ${Theme.Typography.FontSize.XSmall}px;
          }

          .ReferaAttachment__description {
            color: ${Theme.Colors.Grayscale.SixtyFour};
            font-family: ${Theme.Typography.FontFamily.Roboto};
            font-weight: ${Theme.Typography.FontWeight.Small};
            font-size: ${Theme.Typography.FontSize.XXSmall}px;
            margin-bottom: 8px;
            line-height: 28px;
          }
        }
      }

      .ReferaAttachment__files {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        padding-bottom: 8px;
        flex: 1;
        overflow-x: auto !important;
        max-height: 180px;
      }
    `}
  }
`

export default Attachments
